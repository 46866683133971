import { Injectable } from '@angular/core';
import { CustomFieldConfig } from '@app/settings/custom-fields/custom-fields.service';
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/api/api.service';
import { Response } from '@app/core/interfaces/api.response';
import { routes } from '@app/core/config/apiRoutes';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@app/core/utils.service';
import { SafeHtml } from '@angular/platform-browser';

export interface RemoveByIdContext {
  id: string;
}

export interface NewsProps {
  enabled: boolean;
  id?: string;
  type?: number;
  text?: string;
  activateAt?: string | number;
}

export interface News {
  mark: NewsProps;
  popup: NewsProps;
}

export interface SoundSettings {
  operatorNotification: boolean;
  theme: number;
  themeName: string;
}

export enum AppealsAutoAssignMode {
  skipMode = 10,
  forceMode = 20,
  priorMode = 30,
}

export interface ProjectData {
  domain: string;
  id: string;
  name: string;
  operator: boolean;
  ownerId: string;
  paidTariff: boolean;
  url: string;
  pushNotificationActive: boolean;
  pushNotificationExist: boolean;
  unreadCount: number;
  timezone?: string | null;
  saniatzedName?: SafeHtml;
  createdAt: any;
  publicApiStatus: boolean;
  publicApiToken: string;
  publicApiWebhook: string;
  publicApiWebhookBroken: boolean;
  activeWebhooks: string;
  roistatWebhook?: string | null;
  news?: News;
  useGroups: boolean; // использует ли проект функционал групп
  soundSettings: SoundSettings;
  appealsAutoAssignMode: AppealsAutoAssignMode; // поведение в случае ответственных операторов
  personCustomFieldsSettings: CustomFieldConfig[] | null,
}

export interface ProjectsResponse extends Response {
  data: ProjectData[];
}

export interface CreateProjectContext {
  name?: string;
  domain?: string;
  tariff?: number;
  operator?: string;
  timezone?: string;
}

export interface UpdateProjectContext {
  id: string;
  name?: string;
  domain?: string;
  tariff?: number;
  operator?: boolean;
  timezone?: string;
  notification?: boolean;
  publicApiStatus?: boolean;
  publicApiToken?: string;
  apiWebhook?: string;
  roistatWebhook?: string;
  activeWebhooks?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ProjectApiService {

  private routes = routes;

  constructor(
    private api: ApiService,
    private http: HttpClient,
    private utils: UtilsService,
  ) { }

  public getProjects(): Observable<ProjectsResponse> {
    return this.api.httpGet(this.routes.getProjects);
  }

  public createProject(data: CreateProjectContext): Observable<Response> {
    return this.api.httpPost(this.routes.createProject, this.utils.snakeKeys(data));
  }

  public updateProject(data: UpdateProjectContext): Observable<Response> {
    return this.api.httpPost(this.routes.updateProject(data), this.utils.snakeKeys(data));
  }

  public updatePublicApi(data: UpdateProjectContext): Observable<Response> {
    return this.api.httpPost(this.routes.updatePublicApi(), this.utils.snakeKeys(data));
  }


  public updateSoundSetting(data: SoundSettings): Observable<Response> {
    return this.api.httpPost(this.routes.updateSoundSettings, this.utils.snakeKeys(data));
  }

  public removeProject(data: RemoveByIdContext): Observable<any> {
    return this.api.httpGet(this.routes.removeProject(data));
  }
}
